.nosotros {

    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__hero {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: url('/images/nosotros/hero.jpg') no-repeat left center / cover;

        @include mq(sm){
            height: 47vh;
            background: url('/images/nosotros/hero.jpg') no-repeat top center fixed / 100%;
        }

        &__text {
            text-align: center;
            padding: 15px;
            background: rgba(255,255,255,.5);
            width: 100%;

            @include mq(sm){
                background:none;
                width: auto;
                max-width: 650px;
                margin: auto;
            }
        }

        &__title {
            font-size: 36px;
            font-weight: 300;

            @include mq(sm){
                font-size: 48px;
            }
        }

        &__subtitle {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }

    &__intro {
        padding: 40px 0;
        background: $lightestGray;

        @include mq(sm){
            padding: 80px 0;
        }

        &__text {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 15px;

            @include mq(sm){
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 20px;
            }

            &__block {
                @include mq(sm){
                    column-count: 2;
                }
            }
        }
    }

    &__staff{
        $a:&;

        padding-top: 40px;

        @include mq(sm){
            padding-top: 80px;
        }

        &__title {
            color: $altBlue;
            text-align: center;
            font-size: 36px;
            font-weight: 300;

            @include mq(sm){
                font-size: 48px;
            }
        }

        &__item {
            padding: 40px 0;
            border-bottom: 1px solid $lightGray;

            @include mq(sm){
                padding: 80px 0;
            }

            &__name {
                font-size: 32px;
                color: $altBlue;
                font-weight: 300;
                margin: 0 0 15px;
            }

            &__pos {
                display: block;
                color: $midGray;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 30px;
            }

            &__text {
                font-size: 14px;
                margin-bottom: 30px;
                line-height: 28px;
            }
        }
    }
}
