.footer {
    background: $lightGray;

    &__top {
        padding: 40px 0;
        border-bottom: 1px solid $lightGray - #111;

        @include mq(sm){
            padding: 80px 0;
        }

        &__title {
            color: $altBlue;
            font-weight: 300;
            font-size: 36px;
            margin: 0 0 20px;
            text-align: center;
            padding-bottom: 40px;
        }

        &__link{
            display: block;
            padding-bottom: 40px;
            position: relative;
            text-transform: uppercase;
            font-weight: 500;
            color: inherit;
            font-size: 12px;

            &:after {
                content: '---';
                text-align: center;
                position: absolute;
                bottom: 15px;
                letter-spacing: -2px;
                color: inherit;
                width: 100%;
                left: 0;
                right: 0;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            @include mq(sm){
                font-size: 16px;
            }
        }

        &__icon {
            margin-bottom: 40px;
        }

        &__col {
            text-align: center;

            &--nutritional {
                color: $yellow;
            }

            &--health {
                color: $purple;
            }

            &--sport {
                color: $green;
            }

            &--baby {
                color: $studyblue;
            }

            &--personalized {
                color: $turqoise;
            }
        }
    }

    &__bottom {
        padding: 20px 0;

        @include mq(sm){
            padding: 40px 0;
        }

        &__left {
            display: flex;
            justify-content: center;

            @include mq(sm){
                justify-content: flex-start;
            }

            img {
                height: 50px;
                display: inline-block;
            }
        }

        &__middle {
            display: flex;
            justify-content: center;
            color: $midGray;
            line-height: 50px;
        }

        &__right {

            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;

            @include mq(sm){
                justify-content: flex-end;
            }
        }

        &__social {
            display: flex;
            margin-right: 15px;

            li {
                margin-right: 15px;

                a{
                    color: $midGray;
                }
            }
        }
    }
}
