.login {
    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__section {
        background: $lightGray;
    }

    &__img {
        height: 0;
        position: relative;
        padding-bottom: 70%;
        overflow: hidden;

        img{
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;

            @include mq(sm){
                min-height: 100%;
                min-width: 100%;
            }
        }
    }

    &__title {
        font-weight: 300;
        text-align: center;
    }

    &__subtitle {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        display: block;
        text-align: center;
        color: $midGray;
    }

    &__form {
        padding: 40px 0;

        &__wrapper {
            max-width: 300px;
            margin: auto;
        }

        &__row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__heading {
            padding-bottom: 50px;
        }

        &__group {
            position:relative;
            padding-bottom: 30px;
            text-align: center;

            &--pass {
                padding-bottom: 60px;
            }

            &--register {
                padding-top: 60px;
                padding-bottom: 0;
            }
        }

        &__label {
            width: 30px;
            height: 30px;
            position: absolute;
            left: 0;
            top: 0;
            line-height: 30px;
            text-align: center;
            font-size: 21px;
        }

        &__input {
            height: 30px;
            font-size: 16px;
            line-height: 30px;
            border: none;
            border-bottom: 1px solid $midGray;
            background: transparent;
            width: 100%;
            padding-left: 30px;
        }

        &__button{
            height: 40px;
            line-height: 37px;
            color: $blue;
            border: 2px solid $blue;
            border-radius: 20px;
            padding: 0 50px;
            background: transparent;
            float: right;
            font-weight: 500;
            width: 100%;
        }

        &__recovery {
            position: absolute;
            right: 0;
            bottom: 30px;
            font-size: 13px;
            color: $midGray;
        }
    }
}
