.header {

    $h: &;

    position: fixed;
    top:0;
    left:0;
    right:0;
    background: $white;
    z-index: 1000;
    height: 40px;
    padding:10px 0;
    height: 60px;

    @include mq(md){
        height: 40px;
        padding:30px 0;
        height: 100px;
    }

    &__container {
        position: relative;
        padding: 0 20px;

        @include mq(md){
            display: flex;
            justify-content: space-between;
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 32px;
        top: 10px;

        span {
            background-color: $link;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        @include mq(md){
            display: none;
        }
    }

    &__logo {

        img {
            height: 40px;
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top:60px;
        bottom:0;
        right:0;
        width:106%;
        background: $white;
        transform: translateX(100%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: $black;
        border-top: 1px solid $blue;
        padding-left: 20px;

        @include mq(md){
            position: relative;
            transform: translateX(0);
            background: transparent;
            padding: 0;
            width: auto;
            display: flex;
            top: 0;
            border: none;
        }
    }

   &__menu {

       @include mq(md){
           display: flex;

           @include mq(md){
               justify-content: flex-start;
               text-align: left;
               margin-bottom: 0;
           }
       }

       & > li{
           position: relative;

           @include mq(md){
               margin-left: 15px;
           }

           @include mq(lg){
               margin-left: 40px;
           }

           &:hover {
              & > #{$h}__submenu {
                  max-height: 180px;
                  padding: 10px 0;

                  @include mq(md){
                      background: $lightestGray;
                      padding: 10px 0 0;
                  }
              }
          }
       }

       & > li > a{
           @include trans;

           color:$midGray;
           font-weight: 300;
           font-size: 21px;
           display: block;
           padding: 0 10px;
           height: 50px;
           display: block;
           line-height: 50px;
           text-transform: uppercase;
           white-space: nowrap;


           @include mq(md){
               position: relative;
               display: flex;
               align-items: center;
               font-size: 14px;
               height: 40px;
               line-height: 40px;
           }

           @include mq(lg){
               padding: 0 20px;
           }

           i {
               color: $blue;
           }

           &:hover {
               color: $link;
           }

           &._button {
               color: $white;

               &:hover {
                   color: $white;
               }
           }
       }

       ._button {
           margin-left: 20px;
           margin-top: 20px;

           @include mq(md){
               margin: 0;
           }
       }
   }

   &__submenu {
       @include trans;
       padding:0;
       width: 100%;
       max-height: 0;
       overflow: hidden;

       @include mq(md) {
           position: absolute;
           top: 100%;
           background: $lightestGray;
           border-bottom: none;
           padding: 0;
           max-height: 0;
           overflow: hidden;
       }

       a {
           color:$midGray;
           font-weight: 300;
           padding-left: 20px;
           display: block;
           text-transform: uppercase;
           font-size: 16px;
           height: 32px;
           line-height: 32px;
           background: $lightestGray;
           border-bottom:1px solid $lightGray;

           @include mq(md){
               padding: 0;
               height: 40px;
               line-height: 40px;
               padding: 0 20px;
               font-size: 14px;
               background: none;
               border-bottom:none;

               &:hover {
                   background: $white;
               }
           }
       }
   }

   &__account {
       @include trans;

       display: none;

       @include mq(md){
           display: flex;
           position: relative;
           width: auto;
       }

       li {
           height: 40px;
           display: flex;
           align-items: center;

           &:last-child {
               margin-left: 20px;
           }
       }

       a {
           background: $lightestGray;
           display: block;
           padding: 5px 20px;
           border-radius: 20px;
           font-size: 14px;
           text-transform: uppercase;
           color: $midGray;
           white-space: nowrap;

           &:hover {
               color: $blue;
               background: $lightGray;
           }
       }
   }

   &.-open {
       #{$h}__hamburguer {
           span:nth-child(1) {
               animation:inT 0.6s $easing forwards;
           }
           span:nth-child(2) {
               animation:inM 0.6s $easing forwards;
           }
           span:nth-child(3) {
               animation:inBtm 0.6s $easing forwards;
           }
       }

       #{$h}__nav {
           transform: translateX(0);
       }

       #{$h}__account {
           transform: translateX(0);
       }
   }
}


//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
