.innerpage {
    $i: &;

    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__hero {
        position: relative;

        &__img {
            overflow: hidden;
            position: relative;
            height: 0;
            padding-bottom: 140%;

            @include mq(sm){
                padding-bottom:35%;
            }

            img {
                position: absolute;
                left: 0;
                bottom:0;
                min-height: 100%;
                min-width: 100%;

                @include mq(sm){
                    top:0;
                    bottom: auto;
                }
            }
        }

        &__container {
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 5%;

            @include mq(sm){
                top: 40%;
            }
        }

        &__data {
            width: 100%;

            @include mq(sm){
                width: 30%;

            }
        }

        &__title {
            font-weight: 300;
        }

        &__subtitle {
            display: block;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__body {
        padding: 40px 0;

        @include mq(sm){
            padding: 80px 0;
        }

        &__title {
            text-align: center;
            margin: 0 0 40px;
            font-weight: 300;
        }

        &__text {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 20px;
        }

        &__icon {
            width: 80%;
            margin: auto;
            display: block;
            max-width: 450px;
        }
    }

    &--como-funciona {
        #{$i}__hero {
            &__img {
                img {
                    min-width: auto;
                    bottom: 0;
                    min-height: auto;
                    width: 100%;

                    @include mq(sm){
                        min-height: 100%;
                        right: 0;
                        left: auto;
                        width: auto;
                    }
                }
            }
        }

        #{$i}__body {
            &__title {
                color: $mainColor;
            }
        }

        &__step {
            display: flex;
            padding-bottom: 40px;

            &__icon {
                flex-basis: 25%;

                img {
                    width: 100%;
                }
            }

            &__data {
                padding-left: 15px;
                flex-basis: 75%;
            }

            &__index {
                display: flex;
                width: 30px;
                height: 30px;
                font-weight: 700;
                color: $white;
                background: $mainColor;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                border-radius: 50%;
                margin-bottom: 15px;
            }

            &__text {
                margin: 0;
                font-size: 20px;
                font-weight: 300;
                line-height: 32px;
            }
        }
    }

    &--adn {
        #{$i}__hero {
            &__data {
                width: 100%;
            }
            &__title {
                color: $white;
            }
        }

        #{$i}__body {
            &__text {
                text-align: center;
                padding-bottom: 40px;
            }
            &__title {
                color: $mainColor;
                text-align: left;
            }
        }
    }

    &--medicina {
        #{$i}__hero {
            &__img {
                @include mq(sm){
                    padding-bottom:30%;
                }
            }
            &__data {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }
            &__title {
                color: $darkestGray;
            }
        }

        #{$i}__body {
            &__text {
                text-align: center;
                padding: 40px 0;
            }
        }
    }
}
