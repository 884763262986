.home {
    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__hero {
        &__mobile {
            @include mq(sm){
                display: none;
            }
        }

        &__slider {
            display: none;

            &.slick-dotted.slick-slider{

                margin-bottom: -6px;
            }

            @include mq(sm){
                display: block;
            }
        }
        &__slide {
            $s:&;

            position: relative;

            &__img {
                overflow: hidden;
                position: relative;
                height: 0;
                padding-bottom: 140%;

                @include mq(sm){
                    padding-bottom: 30%;
                }

                img {
                    position: absolute;
                    left: 0;
                    bottom:0;
                    min-height: 100%;
                    min-width: 100%;

                    @include mq(sm){
                        top:0;
                        bottom: auto;
                    }
                }
            }

            &__data {
                position: absolute;
                right: 0;
                width: 100%;
                z-index: 2;
                top: 60px;
                bottom: 0;
                display: flex;
                flex-direction: column;
                text-align: center;

                @include mq(sm){
                    top: 0;
                    width: 40%;
                    text-align: left;
                    justify-content: center;
                }
            }

            &__title {
                font-weight: 300;
                color: $white;
                margin: 0 0 20px;
                font-size: 24px;

                @include mq(sm){
                    font-size: 42px;
                    width: 70%;
                }
            }

            &__btn {
                height: 50px;
                line-height: 47px;
                font-size: 16px;
                padding: 0 30px;
                border: 2px solid $white;
                border-radius: 25px;
                color: $white;
                align-self:center;
                font-weight: 500;

                @include mq(sm){
                    align-self: flex-start;
                }

                &:hover {
                    background: rgba(255,255,255,.2);
                    color: $white;
                }
            }

            &--alt {
                #{$s}__title {
                    color: $darkestGray;
                }

                #{$s}__btn {
                    border-color: $blue;
                    color: $blue;

                    &:hover {
                        color: $blue;
                    }
                }
            }
        }

        &__cta {
            background: $blue;
            display: flex;
            color: $white;
            justify-content: center;
            padding: 15px;

            &:hover {
                color: $white;
            }

            &__text {
                max-width: 500px;
                display: flex;
                align-items: center;
                font-size: 14px;

                img {
                    font-size: 16px;
                    width: 34px;
                    height: 34px;
                    margin-right: 15px;
                }
            }

            &__button {
                display: none;

                @include mq(sm){
                    display: block;
                    height: 50px;
                    line-height: 47px;
                    font-size: 16px;
                    padding: 0 30px;
                    border: 2px solid $white;
                    border-radius: 25px;
                    color: $white;
                    align-self: flex-start;
                    font-weight: 500;

                    &:hover {
                        background: rgba(255,255,255,.2);
                    }
                }
            }
        }
    }

    &__estudios {
        background: $lightGray;
        padding:40px 0;

        @include mq(md){
            padding:80px 0;
        }

        &__title {
            color: $altBlue;
            font-weight: 300;
            text-align: center;
            margin-bottom: 30px;
        }

        &__slider {
            .slick-dots {
                bottom: -50px;

                button:before {
                    color: $darkGray;
                }
            }

            li.slick-active {
                button:before {
                    color: $darkGray;
                }
            }
        }

        &__slide {
            padding: 10px 20px;
        }

        &__item {
            $i:&;

            background: $white;
            padding: 30px 0 0;
            text-align: center;
            width: 90%;
            max-width: 370px;
            box-shadow: 0 0 10px rgba(0,0,0,.15);
            min-height: 530px;
            position: relative;
            margin: auto;

            &__img {
                position: absolute;
                overflow: hidden;
                height: 0;
                padding: 30%;
                bottom: 0;
                width: 100%;

                img {
                    position: absolute;
                    min-height: 100%;
                    min-width: 100%;
                    bottom: 0;
                    right: 0;
                }
            }

            &__icon {
                text-align: center;
                padding-bottom: 20px;

                img {
                    display: inline-block;
                }
            }

            &__name {
                margin: 0 0 10px;
            }

            &__text {
                margin: 0 0 20px;
                padding: 0 20px;
                font-size: 14px;
            }

            &__button {
                border-radius: 20px;
                height: 40px;
                line-height: 38px;
                color: $white;
                font-size: 16px;
                font-weight: 500;
                display: inline-block;
                padding: 0 25px;

                &:hover {
                    color: $white;
                }
            }

            &__price {
                font-weight: 300;
                display: block;
                margin: 0 0 20px;
            }

            &--turqoise {
                #{$i}__price {
                    color: $turqoise;
                }

                #{$i}__button {
                    background: $turqoise;

                    &:hover {
                        background: $turqoise - #333;
                    }
                }
            }

            &--yellow {
                #{$i}__price {
                    color: $yellow;
                }

                #{$i}__button {
                    background: $yellow;

                    &:hover {
                        background: $yellow - #333;
                    }
                }
            }

            &--blue {
                #{$i}__price {
                    color: $studyblue;
                }

                #{$i}__button {
                    background: $studyblue;

                    &:hover {
                        background: $studyblue - #333;
                    }
                }
            }

            &--green {
                #{$i}__price {
                    color: $green;
                }

                #{$i}__button {
                    background: $green;

                    &:hover {
                        background: $green - #333;
                    }
                }
            }

            &--purple {
                #{$i}__price {
                    color: $purple;
                }

                #{$i}__button {
                    background: $purple;

                    &:hover {
                        background: $purple - #333;
                    }
                }
            }
        }
    }

    &__saliva {

        &__row {
            display: flex;
            flex-wrap: wrap;
        }

        &__text {
            &__col {
                display: flex;
                justify-content: center;
                align-items: center;

                @include mq(sm){
                    flex-basis: 40%;
                    order: 2;
                }
            }

            &__title {
                color: $midGray;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                padding: 0 15px;
            }

            &__desc {
                font-size: 24px;
                font-weight: 300;
                color: $altBlue;
                padding: 0 15px;

                @include mq(sm){
                    font-size: 36px;
                    width: 60%;
                }
            }

            &__link {
                color: $blue;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                padding: 0 15px;
            }
        }

        &__img {
            height: 0;
            position: relative;
            overflow: hidden;
            padding-bottom: 110%;

            @include mq(sm){
                padding-bottom: 90%;
            }

            img {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;

                @include mq(sm){
                    min-width: 100%;
                    min-height: 100%;
                }
            }

            &__col {
                @include mq(sm){
                    order: 1;
                    flex-basis: 60%;
                }
            }
        }
    }
}
