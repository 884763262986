.contacto {
    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__hero {
        padding: 40px 0;
        background: linear-gradient(to bottom right, $studyblue 10%, $blue);

        @include mq(sm){
            padding: 80px 0;
        }

        &__title {
            font-size: 36px;
            font-weight: 300;

            @include mq(sm){
                font-size: 42px;
            }
        }

        &__subtitle {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
        }

        &__text {
            color: $white;
            text-align: center;
            margin: auto;
        }
    }

    &__form {
        padding: 40px 0;
        background: $lightGray;

        @include mq(sm){
            min-height: 70vh;
            display: flex;
            align-items: center;
            background: url('/images/contacto/form.jpg') no-repeat center center / cover;
        }

        &__title {
            color: $altBlue;
            font-weight: 300;
            font-size: 36px;
            margin-bottom: 30px;

            &--auxiliar {
                display: none;

                @include mq(sm){
                    display: block;
                }
            }
        }

        &__group {
            margin-bottom: 30px;
        }

        &__input {
            height: 30px;
            font-size: 16px;
            line-height: 30px;
            border: none;
            border-bottom: 1px solid $midGray;
            background: transparent;
            width: 100%;
        }

        &__button{
            height: 40px;
            line-height: 37px;
            color: $blue;
            border: 2px solid $blue;
            border-radius: 20px;
            padding: 0 50px;
            background: transparent;
            float: right;
            font-weight: 500;
        }
    }

    &__location {
        display: flex;
        flex-wrap: wrap;

        &__text {
            flex-basis: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq(sm){
                flex-basis: 30%;
            }

            &__box {
                padding: 15px;

                @include mq(sm){
                    width: 200px;
                    padding: 0;
                }
            }
        }

        &__title {
            color: $altBlue;
            font-weight: 300;
            margin-bottom: 30px;
        }

        &__map {
            flex-basis: 100%;

            @include mq(sm){
                flex-basis: 70%;
            }

            .embed-responsive {
                padding-bottom:100%;
                @include mq(sm){

                    padding-bottom: 70%;
                }
            }
        }
    }
}
