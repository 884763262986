.estudio {
    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__hero {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq(sm){
            height: 47vh;
        }

        &--nutritional {
            background: url('/images/estudios/nutritional-hero.jpg') no-repeat left center / cover;

            @include mq(sm){
                background: url('/images/estudios/nutritional-hero.jpg') no-repeat top center fixed / 100%;
            }
        }

        &--baby {
            background: url('/images/estudios/baby-hero.jpg') no-repeat -540px center / cover;

            @include mq(sm){
                background: url('/images/estudios/baby-hero.jpg') no-repeat top center fixed / 100%;
            }
        }

        &--health {
            background: url('/images/estudios/heal-hero.jpg') no-repeat right center / cover;

            @include mq(sm){
                background: url('/images/estudios/heal-hero.jpg') no-repeat top center fixed / 100%;
            }
        }

        &--personalized {
            background: url('/images/estudios/pers-hero.jpg') no-repeat right center / cover;

            @include mq(sm){
                background: url('/images/estudios/pers-hero.jpg') no-repeat top center fixed / 100%;
            }
        }

        &--sport {
            background: url('/images/estudios/sports-hero.jpg') no-repeat -200px center / cover;

            @include mq(sm){
                background: url('/images/estudios/sports-hero.jpg') no-repeat top center fixed / 100%;
            }
        }

        &--ancestral {
            background: url('/images/estudios/ancestral-hero.jpg') no-repeat -200px center / cover;

            @include mq(sm){
                background: url('/images/estudios/ancestral-hero.jpg') no-repeat top center fixed / 100%;
            }

        }

        &__text {
            text-align: center;
            padding: 15px;
            background: rgba(255,255,255,.5);
            width: 100%;

            @include mq(sm){
                background:none;
                width: auto;
            }
        }

        &__icon {
            display: inline-block;
        }

        &__title {
            font-size: 36px;
            font-weight: 500;

            @include mq(sm){
                font-size: 48px;
            }
        }
    }

    &__intro {
        $i:&;

        padding: 40px 0;
        background: $lightGray;

        @include mq(sm){
            padding: 80px 0;
        }

        &__text {
            font-size: 18px;
            line-height: 32px;
            margin-bottom: 20px;

            @include mq(sm){
                font-size: 24px;
                line-height: 40px;
                margin-bottom: 30px;
            }
        }

        &__price {
            display: block;
            font-size: 32px;
            font-weight: 300;
            margin-bottom: 20px;
            text-align: center;

            @include mq(sm){
                margin-bottom: 30px;
            }
        }

        &__cta {
            display: inline-block;
            height: 50px;
            line-height: 50px;
            border-radius: 25px;
            padding: 0 30px;
            color: $white;
            font-weight: 500;
            margin-right: 15px;

            &:hover{
                color: $white;
            }

            &__wrapper {
                text-align: center;
            }
        }

        &--nutritional {
            #{$i}__price {
                color: $yellow;
            }

            #{$i}__cta {
                background: $yellow;
            }
        }

        &--baby {
            #{$i}__price {
                color: $studyblue;
            }

            #{$i}__cta {
                background: $studyblue;
            }
        }

        &--health {
            #{$i}__price {
                color: $purple;
            }

            #{$i}__cta {
                background: $purple;
            }
        }

        &--personalized {
            background: $white;

            #{$i}__price {
                color: $turqoise;
            }

            #{$i}__cta {
                background: $turqoise;
            }
        }

        &--sport {
            background: $white;

            #{$i}__price {
                color: $green;
            }

            #{$i}__cta {
                background: $green;
            }
        }

        &--ancestral {
            background: $white;

            #{$i}__price {
                color: $turqoise;
            }

            #{$i}__cta {
                background: $turqoise;
            }
        }
    }

    &__adicionales{
        $a:&;

        padding-top: 40px;

        @include mq(sm){
            padding-top: 80px;
        }

        &__title {
            color: $altBlue;
            text-align: center;
            font-size: 36px;
            font-weight: 300;

            @include mq(sm){
                font-size: 48px;
            }
        }

        &__list {

        }

        &__item {
            $it: &;

            padding: 40px 0;

            @include mq(sm){
                padding: 80px 0;
            }

            &:nth-child(even) {
                background: $lightestGray;

                #{$it}__imgcol {
                    float: right;
                }
            }

            &__imgcol{
                text-align: center;

                img {
                    display: inline-block;
                    width: 100px;

                    @include mq(sm){
                        width: auto;
                    }
                }
            }

            &__name {
                color: $altBlue;
                text-transform: uppercase;
            }

            &__text {
                line-height: 28px;
                margin-bottom: 20px;

                @include mq(sm){
                    margin-bottom: 30px;
                }
            }

            &__price {
                display: block;
                font-size: 32px;
                font-weight: 300;
                margin-bottom: 20px;
            }

            &__button {
                display: inline-block;
                height: 50px;
                line-height: 50px;
                border-radius: 25px;
                padding: 0 30px;
                color: $white;
                font-weight: 500;
                margin-right: 15px;

                &:hover{
                    color: $white;
                }
            }
        }

        &--nutritional {

            #{$a}__item__price {
                color: $yellow;
            }

            #{$a}__item__button {
                background: $yellow;
            }
        }

        &--baby {

            #{$a}__item__price {
                color: $studyblue;
            }

            #{$a}__item__button {
                background: $studyblue;
            }
        }

        &--health {

            #{$a}__item__price {
                color: $purple;
            }

            #{$a}__item__button {
                background: $purple;
            }
        }
    }
}
