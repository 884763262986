.contratar {
    padding: 60px 0 0;

    @include mq(md){
        padding: 100px 0 0;
    }

    &__main {
        padding: 40px 0;

        @include mq(md){
            padding: 80px 0;
        }
    }

    &__title {
        font-size: 36px;
        text-align: center;
        padding-bottom: 20px;

        @include mq(sm){
            font-size: 48px;
            padding-bottom: 40px;
        }
    }

    &__subtitle {
        padding: 20px 0;
        text-align: center;

        @include mq(md){
            padding: 40px 0;
        }
    }

    &__form {
        $f:&;

        &__submit {
            padding: 20px 0;
            text-align: center;

            @include mq(md){
                padding: 40px 0;
            }
        }

        &__set {
            @include mq(sm){
                display: flex;
            }

            #{$f}__group {
                padding: 10px 0;
                border-bottom: 1px solid $lightGray - #111;

                @include mq(sm){
                    flex-basis: 50%;
                }

                &:last-child {
                    @include mq(sm){
                        text-align: right;

                        input {
                            text-align: right;
                        }
                    }
                }
            }

            #{$f}__input {
                border: none;
                background: transparent;
                font-weight: 700;
                font-size: 18px;
                width: 100%;
                height: auto;
                line-height: normal;
            }
            #{$f}__label {
                @include mq(sm){
                    border-bottom: 1px solid $lightGray - #111;
                    padding-bottom: 5px;
                }
            }
        }

        &__group {
            padding-bottom: 20px;
        }

        &__label {
            display: block;
            font-weight: 300;
        }

        &__input {
            width: 100%;
            background: $white;
            border: 1px solid $lightGray - #111;
            height: 30px;
            padding: 0 5px;
            height: 40px;
            line-height: 40px;
        }

        &__button {
            background: $blue;
            border-radius: 25px;
            height: 50px;
            line-height: 50px;
            padding: 0 40px;
            border: none;
            color: $white;
            font-weight: 500;
        }
    }
}
